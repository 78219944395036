<div
  *ngIf="!standalone"
  class="p-lg md:p-base pb-2xl md:border-b md:border-gray-100 text-base leading-base font-medium"
>
  Select dates
</div>

<div class="flex-1 flex flex-col md:flex-row">
  <div *ngIf="!hideAllPeriods" class="pills-container" [ngClass]="{ '!pl-0': standalone }">
    <mg-pill
      class="md:hidden"
      extraClass="w-max min-w-max"
      *ngFor="let item of periodTypeButtonsInfo"
      [selected]="value?.periodType === item.value"
      [label]="item.label"
      size="m"
      (click)="setDateByPeriodType(item)"
    >
    </mg-pill>

    <mg-pill
      class="hidden md:block"
      extraClass="w-max min-w-max"
      *ngFor="let item of periodTypeButtonsInfo"
      [selected]="value?.periodType === item.value"
      [label]="item.label"
      size="s"
      (click)="setDateByPeriodType(item)"
    >
    </mg-pill>
  </div>

  <div class="flex-1 flex flex-col">
    <form
      [formGroup]="rangeForm"
      class="flex items-center mb-sm md:mb-xs -mt-sm px-xl pt-2xl md:pt-lg md:px-base md:pb-0"
    >
      <mg-input
        class="flex-1"
        extraInputClass="!text-base"
        controlName="fromText"
        label="Start date"
        type="text"
      ></mg-input>
      <div class="px-xs text-txt-secondary">-</div>
      <mg-input
        class="flex-1"
        extraInputClass="!text-base"
        controlName="toText"
        label="End date"
        type="text"
      ></mg-input>
    </form>

    <div class="flex-1 px-xs pb-0" [ngClass]="{ '!pb-0': standalone }">
      <mat-calendar
        #calendar
        class="mg-date-range-picker-calendar"
        [selected]="selectedDateRange"
        (selectedChange)="onSelectedDateChange($event)"
        [maxDate]="maxDate"
        [minDate]="minDate ?? undefined"
      ></mat-calendar>
    </div>
  </div>
</div>

<div *ngIf="!standalone" class="md:hidden flex flex-col mt-lg px-xl mb-xl">
  <mg-button class="mb-base flex-1" size="small" (click)="fireValueChangedEvent()"> Apply </mg-button>
  <mg-button class="flex-1" size="small" variant="secondary" (click)="canceld.emit()">
    Cancel
  </mg-button>
</div>

<div *ngIf="!standalone" class="hidden md:flex items-center justify-end p-base border-t border-gray-100">
  <mg-button class="mr-2xl" variant="ghost" size="small-shrink" (click)="canceld.emit()">
    Cancel
  </mg-button>
  <mg-button variant="ghost" size="small-shrink" (click)="fireValueChangedEvent()"> Apply </mg-button>
</div>
