import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import { MainLayoutComponent } from './main-layout/main-layout.component'
import { CoreFeatureModule } from '@mg-platform/core/core-feature'

@NgModule({
  declarations: [MainLayoutComponent],
  imports: [CommonModule, RouterModule, CoreFeatureModule],
  exports: [MainLayoutComponent]
})
export class MainLayoutModule {}
