import {
  IAdminGetPosTypeRequest,
  IAdminUpdatePosTypeRequest,
  IRunShopImportRequest
} from '../../interfaces/admin-pos-type.interface'

export class GetAdminShopPosType {
  static readonly type = '[AdminPosType] Get Admin Shop Pos Type'
  constructor(public shopId: string, public payload: IAdminGetPosTypeRequest) {}
}

export class UpdateAdminShopPosType {
  static readonly type = '[AdminPosType] Update Admin Shop Pos Type'
  constructor(public shopId: string, public payload: IAdminUpdatePosTypeRequest) {}
}

export class AdminRunShopImport {
  static readonly type = '[AdminPosType] Admin Run Shop Import'
  constructor(public payload: IRunShopImportRequest) {}
}
