import { Component, inject, OnInit } from '@angular/core'
import { ReferralType, UserAccountState } from '@mg-platform/users/users-data-access'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { Store } from '@ngxs/store'
import { Observable } from 'rxjs'

@UntilDestroy()
@Component({
  selector: 'mg-admin-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.scss']
})
export class AdminAuthLayoutComponent implements OnInit {
  referralType$: Observable<ReferralType> = inject(Store).select(UserAccountState.referralType)
  referralType: ReferralType

  referralTypes = ReferralType

  ngOnInit(): void {
    setTimeout(() => {
      this.referralType$.pipe(untilDestroyed(this)).subscribe((res) => {
        this.referralType = res
      })
    })
  }
}
