import { Injectable } from '@angular/core'
import { ShopsService } from '../../shops.service'
import { Action, Selector, State, StateContext, Store } from '@ngxs/store'
import { ShopGroupsStateModel } from './shop-groups.model'
import {
  AutoCreateShopGroups,
  DeleteShopGroup,
  SetShopGroups,
  UpdateShopGroups
} from './shop-groups.actions'
import { ResetCompareWith } from '@mg-platform/core/core-data-access'
import { tap } from 'rxjs'

@State<ShopGroupsStateModel>({
  name: 'shopGroups'
})
@Injectable()
export class ShopGroupsState {
  constructor(
    private shopsService: ShopsService,
    private store: Store
  ) {}

  @Selector()
  static groups(state: ShopGroupsStateModel) {
    return state?.groups ?? []
  }

  @Action(UpdateShopGroups)
  updateShopGroups(ctx: StateContext<ShopGroupsStateModel>, { payload }: UpdateShopGroups) {
    return this.shopsService.updateShopGroup(payload).pipe(
      tap(() => {
        this.store.dispatch(new ResetCompareWith())
      })
    )
  }

  @Action(DeleteShopGroup)
  deleteShopGroups(ctx: StateContext<ShopGroupsStateModel>, { groupId }: DeleteShopGroup) {
    return this.shopsService.deleteShopGroup(groupId).pipe(
      tap(() => {
        this.store.dispatch(new ResetCompareWith())
      })
    )
  }

  @Action(SetShopGroups)
  setShopGroups(ctx: StateContext<ShopGroupsStateModel>, { shopGroups }: SetShopGroups) {
    ctx.patchState({ groups: shopGroups ?? [] })
  }

  @Action(AutoCreateShopGroups)
  autoCreateShopGroups(ctx: StateContext<ShopGroupsStateModel>) {
    return this.shopsService.autoCreateShopGroups().pipe(
      tap(() => {
        this.store.dispatch(new ResetCompareWith())
      })
    )
  }
}
