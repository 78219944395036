import { Injectable } from '@angular/core'
import { HttpService } from '@mg-platform/core/core-data-access'
import { Observable } from 'rxjs'
import {
  IAllOrganizationsResponse,
  IOrganizationDetails,
  IUpdateOrganizationRequest
} from './interfaces/organizations.interface'
import { ORGANIZATIONS_API_URL } from './organizations-api-routes'
import { ITableAction, TableActionsService } from '@mg-platform/core/core-feature'
import { DateService } from '@mg-platform/core/core-util'
import { OrganizationFeatures } from '@mg-platform/users/users-data-access'

@Injectable({
  providedIn: 'root'
})
export class OrganizationsService {
  constructor(
    private http: HttpService,
    private tableActionsService: TableActionsService,
    private dateService: DateService
  ) {}

  getAllOrganizations(info: ITableAction): Observable<IAllOrganizationsResponse> {
    return this.http.post<IAllOrganizationsResponse>(
      ORGANIZATIONS_API_URL.getAllOrganizations,
      this.tableActionsService.createRequestPayload(info)
    )
  }

  getOneOrganization(organizationId: string): Observable<IOrganizationDetails> {
    return this.http.get<IOrganizationDetails>(ORGANIZATIONS_API_URL.base(organizationId))
  }

  updateOrganization(organizationId: string, payload: IUpdateOrganizationRequest) {
    return this.http.put(ORGANIZATIONS_API_URL.base(organizationId), {
      ...payload,
      subscriptionExpireDate: payload.subscriptionExpireDate
        ? this.dateService.momentToDateString(payload.subscriptionExpireDate)
        : undefined
    })
  }

  deleteOrganizationMember(organizationId: string, userId: string) {
    return this.http.delete(ORGANIZATIONS_API_URL.deleteOrganizationMember(organizationId, userId))
  }

  getAllOrganizationFeatures(): Observable<OrganizationFeatures[]> {
    return this.http.get(ORGANIZATIONS_API_URL.getAllOrganizationFeatures)
  }
}
