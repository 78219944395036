import { ApplicationRoutes } from '../../app-routes-names'

export const ProfileRoutes = {
  editProfile: 'edit-profile',
  editPassword: 'edit-password'
}

const { profile } = ApplicationRoutes

export const ProfilePaths = {
  editProfile: `/${profile}/${ProfileRoutes.editProfile}`,
  editPassword: `/${profile}/${ProfileRoutes.editPassword}`
}
