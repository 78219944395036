import { inject, Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router'
import { IUser, Logout, Role, UserAccountState } from '@mg-platform/users/users-data-access'
import { Store } from '@ngxs/store'
import { ToastrService } from 'ngx-toastr'
import { map, Observable, take } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class OnlyAdminGuard {
  currentUser$: Observable<IUser> = inject(Store).select(UserAccountState.currentUser)

  constructor(private store: Store, private router: Router, private toastrService: ToastrService) {}

  canActivate(
    route: ActivatedRouteSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.currentUser$.pipe(
      take(1),
      map((user) => {
        if (user?.role === Role.user) {
          this.store.dispatch(new Logout())
          this.router.navigateByUrl('/login')
          this.toastrService.error('You cannot use the Admin Panel with a user account.')
          return false
        }
        return true
      })
    )
  }
}
