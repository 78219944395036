import { environment } from '@mg-platform/core/core-util'

export const ORGANIZATIONS_API_URL = {
  base: (organizationId?: string) =>
    `${environment.apiUrl}/admin/organizations${organizationId ? '/' + organizationId : ''}`,
  getAllOrganizations: `${environment.apiUrl}/admin/filters/organizations`,
  deleteOrganizationMember: (organizationId: string, userId: string) =>
    `${environment.apiUrl}/admin/organizations/${organizationId}/${userId}`,
  getAllOrganizationFeatures: `${environment.apiUrl}/admin/organizations/features`
}
