import { Injectable } from '@angular/core'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { tap } from 'rxjs/operators'
import { AdminReportsService } from '../../admin-reports.service'
import { AdminPosTypeStateModel } from './admin-pos-type.model'
import {
  AdminRunShopImport,
  GetAdminShopPosType,
  UpdateAdminShopPosType
} from './admin-pos-type.actions'

@State<AdminPosTypeStateModel>({
  name: 'adminPosType'
})
@Injectable()
export class AdminPosTypeState {
  constructor(private adminReportsService: AdminReportsService) {}

  @Selector()
  static shopPosType(state: AdminPosTypeStateModel) {
    return state.shopPosType
  }

  @Action(GetAdminShopPosType)
  getAdminCalendarInfo(
    ctx: StateContext<AdminPosTypeStateModel>,
    { shopId, payload }: GetAdminShopPosType
  ) {
    ctx.patchState({ shopPosType: undefined })
    return this.adminReportsService.getShopPosType(shopId, payload).pipe(
      tap((res) => {
        ctx.patchState({ shopPosType: res })
      })
    )
  }

  @Action(UpdateAdminShopPosType)
  updateAdminShopPosType(
    ctx: StateContext<AdminPosTypeStateModel>,
    { shopId, payload }: UpdateAdminShopPosType
  ) {
    return this.adminReportsService.updateShopPosType(shopId, payload)
  }

  @Action(AdminRunShopImport)
  adminRunShopImport(ctx: StateContext<AdminPosTypeStateModel>, { payload }: AdminRunShopImport) {
    return this.adminReportsService.runShopImport(payload)
  }
}
