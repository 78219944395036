import { Injectable } from '@angular/core'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { tap } from 'rxjs/operators'
import { AdminReportsService } from '../../admin-reports.service'
import {
  GetAdminCalendarInfo,
  GetCountReport,
  GetShopWithReports,
  SetAdminCalendarFilters
} from './admin-reports.actions'
import { AdminReportsStateModel } from './admin-reports.model'

@State<AdminReportsStateModel>({
  name: 'adminReports'
})
@Injectable()
export class AdminReportsState {
  constructor(private adminReportsService: AdminReportsService) {}

  @Selector()
  static calendarInfo(state: AdminReportsStateModel) {
    return state.calendarInfo
  }

  @Selector()
  static calendarFilters(state: AdminReportsStateModel) {
    return state.calendarFilters
  }

  @Selector()
  static countReportInfo(state: AdminReportsStateModel) {
    return state.countReportInfo
  }

  @Selector()
  static shopWithReports(state: AdminReportsStateModel) {
    return state.shopWithReports ?? []
  }

  @Action(GetAdminCalendarInfo)
  getAdminCalendarInfo(ctx: StateContext<AdminReportsStateModel>, { payload }: GetAdminCalendarInfo) {
    return this.adminReportsService.calendar(payload).pipe(
      tap((res) => {
        ctx.patchState({ calendarInfo: res })
      })
    )
  }

  @Action(SetAdminCalendarFilters)
  setAdminCalendarFilters(
    ctx: StateContext<AdminReportsStateModel>,
    { payload }: SetAdminCalendarFilters
  ) {
    ctx.patchState({ calendarFilters: payload })
  }

  @Action(GetCountReport)
  getCountReport(ctx: StateContext<AdminReportsStateModel>, { excludeBatch }: GetCountReport) {
    return this.adminReportsService.getCountReport(excludeBatch).pipe(
      tap((res) => {
        ctx.patchState({ countReportInfo: res })
      })
    )
  }

  @Action(GetShopWithReports)
  getShopWithReports(ctx: StateContext<AdminReportsStateModel>, { payload }: GetShopWithReports) {
    return this.adminReportsService.getShopWithReports(payload).pipe(
      tap((res) => {
        ctx.patchState({ shopWithReports: res?.shopReportsItems ?? [] })
      })
    )
  }
}
