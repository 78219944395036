import { ITableAction } from '@mg-platform/core/core-feature'
import { IUpdateOrganizationRequest } from '../../interfaces/organizations.interface'

export class GetAllOrganizations {
  static readonly type = '[Organizations] Get All Organizations'
  constructor(public info: ITableAction) {}
}

export class GetOneOrganization {
  static readonly type = '[Organizations] Get One Organization'
  constructor(public organizationId: string) {}
}

export class UpdateOrganization {
  static readonly type = '[Organizations] Update Organization'
  constructor(
    public organizationId: string,
    public payload: IUpdateOrganizationRequest
  ) {}
}

export class DeleteOrganizationMember {
  static readonly type = '[Organizations] Delete Organization Member'
  constructor(
    public organizationId: string,
    public userId: string
  ) {}
}

export class GetAllOrganizationFeatures {
  static readonly type = '[Organizations] Get All Organization Features'
}
