import { mgPlatformAddCircleIcon } from './add-circle'
import { mgPlatformAddIcon } from './add'
import { mgPlatformArrowBackIcon } from './arrow-back'
import { mgPlatformArrowDownFullIcon } from './arrow-down-full'
import { mgPlatformArrowDownSimpleIcon } from './arrow-down-simple'
import { mgPlatformArrowDownIcon } from './arrow-down'
import { mgPlatformArrowLeftIcon } from './arrow-left'
import { mgPlatformArrowNorthEastIcon } from './arrow-north-east'
import { mgPlatformArrowRightFullIcon } from './arrow-right-full'
import { mgPlatformArrowRightIcon } from './arrow-right'
import { mgPlatformArrowSouthEastIcon } from './arrow-south-east'
import { mgPlatformArrowUpFullIcon } from './arrow-up-full'
import { mgPlatformArrowUpIcon } from './arrow-up'
import { mgPlatformAutoGraphIcon } from './auto-graph'
import { mgPlatformBallotIcon } from './ballot'
import { mgPlatformBarChartIcon } from './bar-chart'
import { mgPlatformBellIcon } from './bell'
import { mgPlatformBenchmarkIcon } from './benchmark'
import { mgPlatformBuildingStoreIcon } from './building-store'
import { mgPlatformBuildingIcon } from './building'
import { mgPlatformBuildingsIcon } from './buildings'
import { mgPlatformCalendarBigIcon } from './calendar-big'
import { mgPlatformCalendarLightNewIcon } from './calendar-light-new'
import { mgPlatformCalendarLightIcon } from './calendar-light'
import { mgPlatformCalendarPlusIcon } from './calendar-plus'
import { mgPlatformCalendarIcon } from './calendar'
import { mgPlatformCarIcon } from './car'
import { mgPlatformChartBigIcon } from './chart-big'
import { mgPlatformChartIncreaseBigIcon } from './chart-increase-big'
import { mgPlatformChartIncreaseIcon } from './chart-increase'
import { mgPlatformChartInsightIcon } from './chart-insight'
import { mgPlatformChartSearchIcon } from './chart-search'
import { mgPlatformChartSquareIcon } from './chart-square'
import { mgPlatformChartIcon } from './chart'
import { mgPlatformCheckCircleIcon } from './check-circle'
import { mgPlatformCircleBorderdIcon } from './circle-borderd'
import { mgPlatformCircleEmptyIcon } from './circle-empty'
import { mgPlatformCircleFillIcon } from './circle-fill'
import { mgPlatformCloseCircleIcon } from './close-circle'
import { mgPlatformCloseLightIcon } from './close-light'
import { mgPlatformCloseIcon } from './close'
import { mgPlatformCloudDoneIcon } from './cloud-done'
import { mgPlatformCongratulationsIcon } from './congratulations'
import { mgPlatformDarkModeIcon } from './dark-mode'
import { mgPlatformDashboardNewIcon } from './dashboard-new'
import { mgPlatformDashboardIcon } from './dashboard'
import { mgPlatformDataTransferIcon } from './data-transfer'
import { mgPlatformDollarIcon } from './dollar'
import { mgPlatformDotsIcon } from './dots'
import { mgPlatformEditIcon } from './edit'
import { mgPlatformExitIcon } from './exit'
import { mgPlatformEyeLightIcon } from './eye-light'
import { mgPlatformEyeIcon } from './eye'
import { mgPlatformFiltersIcon } from './filters'
import { mgPlatformFlagIcon } from './flag'
import { mgPlatformFlashOnIcon } from './flash-on'
import { mgPlatformGarageLightIcon } from './garage-light'
import { mgPlatformGarageIcon } from './garage'
import { mgPlatformGlobeIcon } from './globe'
import { mgPlatformGroupIcon } from './group'
import { mgPlatformHourglassIcon } from './hourglass'
import { mgPlatformInfoCircleIcon } from './info-circle'
import { mgPlatformInsightIcon } from './insight'
import { mgPlatformLightModeIcon } from './light-mode'
import { mgPlatformListIcon } from './list'
import { mgPlatformLockIcon } from './lock'
import { mgPlatformLogoutIcon } from './logout'
import { mgPlatformMailIcon } from './mail'
import { mgPlatformMapIcon } from './map'
import { mgPlatformMenuIcon } from './menu'
import { mgPlatformMinusIcon } from './minus'
import { mgPlatformMonetizationOnIcon } from './monetization-on'
import { mgPlatformNotVerifiedIcon } from './not-verified'
import { mgPlatformPageLandscapeIcon } from './page-landscape'
import { mgPlatformPaletteIcon } from './palette'
import { mgPlatformPatrolRefuleIcon } from './patrol-refule'
import { mgPlatformPhoneIcon } from './phone'
import { mgPlatformPinIcon } from './pin'
import { mgPlatformPlusIcon } from './plus'
import { mgPlatformPresentationBigIcon } from './presentation-big'
import { mgPlatformPresentationIcon } from './presentation'
import { mgPlatformPriceTagIcon } from './price-tag'
import { mgPlatformPrinterIcon } from './printer'
import { mgPlatformRankingsIcon } from './rankings'
import { mgPlatformRefreshIcon } from './refresh'
import { mgPlatformRegionIcon } from './region'
import { mgPlatformSalesTrackerIcon } from './sales-tracker'
import { mgPlatformSearchIcon } from './search'
import { mgPlatformSettingsIcon } from './settings'
import { mgPlatformShopStoreIcon } from './shop-store'
import { mgPlatformSparklesIcon } from './sparkles'
import { mgPlatformSpreadsheetBigIcon } from './spreadsheet-big'
import { mgPlatformSpreadsheetIcon } from './spreadsheet'
import { mgPlatformStarIcon } from './star'
import { mgPlatformSwitchIcon } from './switch'
import { mgPlatformSystemModeIcon } from './system-mode'
import { mgPlatformTargetBigIcon } from './target-big'
import { mgPlatformTargetIcon } from './target'
import { mgPlatformTickIcon } from './tick'
import { mgPlatformTrashIcon } from './trash'
import { mgPlatformTreeIcon } from './tree'
import { mgPlatformTrendIcon } from './trend'
import { mgPlatformTrendingUpBigIcon } from './trending-up-big'
import { mgPlatformTrendingUpIcon } from './trending-up'
import { mgPlatformTriangleIcon } from './triangle'
import { mgPlatformUpstairsBigIcon } from './upstairs-big'
import { mgPlatformUpstairsIcon } from './upstairs'
import { mgPlatformUserCircleIcon } from './user-circle'
import { mgPlatformUserCrossIcon } from './user-cross'
import { mgPlatformUserPlusBoldIcon } from './user-plus-bold'
import { mgPlatformUserPlusIcon } from './user-plus'
import { mgPlatformUserIcon } from './user'
import { mgPlatformUsersCircleIcon } from './users-circle'
import { mgPlatformUsersIcon } from './users'
import { mgPlatformVerifiedIcon } from './verified'
import { mgPlatformVerticalAlignCenterTriangleIcon } from './vertical-align-center-triangle'
import { mgPlatformWarningRoundIcon } from './warning-round'
import { mgPlatformWheelIcon } from './wheel'
export const commonIcons = [
  mgPlatformAddCircleIcon,
  mgPlatformAddIcon,
  mgPlatformArrowBackIcon,
  mgPlatformArrowDownFullIcon,
  mgPlatformArrowDownSimpleIcon,
  mgPlatformArrowDownIcon,
  mgPlatformArrowLeftIcon,
  mgPlatformArrowNorthEastIcon,
  mgPlatformArrowRightFullIcon,
  mgPlatformArrowRightIcon,
  mgPlatformArrowSouthEastIcon,
  mgPlatformArrowUpFullIcon,
  mgPlatformArrowUpIcon,
  mgPlatformAutoGraphIcon,
  mgPlatformBallotIcon,
  mgPlatformBarChartIcon,
  mgPlatformBellIcon,
  mgPlatformBenchmarkIcon,
  mgPlatformBuildingStoreIcon,
  mgPlatformBuildingIcon,
  mgPlatformBuildingsIcon,
  mgPlatformCalendarBigIcon,
  mgPlatformCalendarLightNewIcon,
  mgPlatformCalendarLightIcon,
  mgPlatformCalendarPlusIcon,
  mgPlatformCalendarIcon,
  mgPlatformCarIcon,
  mgPlatformChartBigIcon,
  mgPlatformChartIncreaseBigIcon,
  mgPlatformChartIncreaseIcon,
  mgPlatformChartInsightIcon,
  mgPlatformChartSearchIcon,
  mgPlatformChartSquareIcon,
  mgPlatformChartIcon,
  mgPlatformCheckCircleIcon,
  mgPlatformCircleBorderdIcon,
  mgPlatformCircleEmptyIcon,
  mgPlatformCircleFillIcon,
  mgPlatformCloseCircleIcon,
  mgPlatformCloseLightIcon,
  mgPlatformCloseIcon,
  mgPlatformCloudDoneIcon,
  mgPlatformCongratulationsIcon,
  mgPlatformDarkModeIcon,
  mgPlatformDashboardNewIcon,
  mgPlatformDashboardIcon,
  mgPlatformDataTransferIcon,
  mgPlatformDollarIcon,
  mgPlatformDotsIcon,
  mgPlatformEditIcon,
  mgPlatformExitIcon,
  mgPlatformEyeLightIcon,
  mgPlatformEyeIcon,
  mgPlatformFiltersIcon,
  mgPlatformFlagIcon,
  mgPlatformFlashOnIcon,
  mgPlatformGarageLightIcon,
  mgPlatformGarageIcon,
  mgPlatformGlobeIcon,
  mgPlatformGroupIcon,
  mgPlatformHourglassIcon,
  mgPlatformInfoCircleIcon,
  mgPlatformInsightIcon,
  mgPlatformLightModeIcon,
  mgPlatformListIcon,
  mgPlatformLockIcon,
  mgPlatformLogoutIcon,
  mgPlatformMailIcon,
  mgPlatformMapIcon,
  mgPlatformMenuIcon,
  mgPlatformMinusIcon,
  mgPlatformMonetizationOnIcon,
  mgPlatformNotVerifiedIcon,
  mgPlatformPageLandscapeIcon,
  mgPlatformPaletteIcon,
  mgPlatformPatrolRefuleIcon,
  mgPlatformPhoneIcon,
  mgPlatformPinIcon,
  mgPlatformPlusIcon,
  mgPlatformPresentationBigIcon,
  mgPlatformPresentationIcon,
  mgPlatformPriceTagIcon,
  mgPlatformPrinterIcon,
  mgPlatformRankingsIcon,
  mgPlatformRefreshIcon,
  mgPlatformRegionIcon,
  mgPlatformSalesTrackerIcon,
  mgPlatformSearchIcon,
  mgPlatformSettingsIcon,
  mgPlatformShopStoreIcon,
  mgPlatformSparklesIcon,
  mgPlatformSpreadsheetBigIcon,
  mgPlatformSpreadsheetIcon,
  mgPlatformStarIcon,
  mgPlatformSwitchIcon,
  mgPlatformSystemModeIcon,
  mgPlatformTargetBigIcon,
  mgPlatformTargetIcon,
  mgPlatformTickIcon,
  mgPlatformTrashIcon,
  mgPlatformTreeIcon,
  mgPlatformTrendIcon,
  mgPlatformTrendingUpBigIcon,
  mgPlatformTrendingUpIcon,
  mgPlatformTriangleIcon,
  mgPlatformUpstairsBigIcon,
  mgPlatformUpstairsIcon,
  mgPlatformUserCircleIcon,
  mgPlatformUserCrossIcon,
  mgPlatformUserPlusBoldIcon,
  mgPlatformUserPlusIcon,
  mgPlatformUserIcon,
  mgPlatformUsersCircleIcon,
  mgPlatformUsersIcon,
  mgPlatformVerifiedIcon,
  mgPlatformVerticalAlignCenterTriangleIcon,
  mgPlatformWarningRoundIcon,
  mgPlatformWheelIcon
]
