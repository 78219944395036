import { Injectable } from '@angular/core'
import { GetAllOrganizationFeatures } from '@mg-platform/organizations/organizations-data-access'
import { Store } from '@ngxs/store'
import { catchError, map, Observable, of, take } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class AdminInfoGuard {
  constructor(private store: Store) {}
  canActivate(): Observable<boolean> {
    return this.store.dispatch([new GetAllOrganizationFeatures()]).pipe(
      catchError(() => {
        return of(null)
      }),
      take(1),
      map(() => true)
    )
  }
}
