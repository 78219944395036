<div>
  <div
    class="flex items-center text-txt font-medium cursor-pointer"
    [ngClass]="{ '!text-primary': filter && filter.items && filter.items.length > 0 }"
    (click)="filterMenuTrigger.openMenu()"
  >
    <span>
      {{ filter.label }}
    </span>
    <div *ngIf="sortDirection" class="ml-sm flex" [ngClass]="{ 'rotate-180': sortDirection === 'desc' }">
      <svg-icon *ngIf="sortDirection" key="arrow-up" class="!text-xs"></svg-icon>
    </div>
  </div>
  <div [matMenuTriggerFor]="filterMenu" #filterMenuTrigger="matMenuTrigger"></div>
</div>

<mat-menu
  #filterMenu="matMenu"
  [hasBackdrop]="true"
  backdropClass="mg-menu-backdrop"
  class="mg-filter-menu"
  (closed)="onMenuClose()"
>
  <div
    class="p-base flex flex-col w-[320px]"
    [ngClass]="{ 'md:!w-[520px]': filter.columnType === tableColumnTypes.date }"
    (click)="$event.stopPropagation()"
    (keydown.Tab)="$event.stopPropagation()"
  >
    <div class="flex items-center justify-end">
      <div
        class="sort-btn mr-sm"
        [ngClass]="{ 'sort-btn-active': sortDirection === 'asc' }"
        (click)="
          filterChanged.emit({
            sort: { active: filter.key, direction: 'asc' }
          })
        "
      >
        {{ filter.columnType === tableColumnTypes.number ? 'Low to high' : 'Ascending' }}
      </div>
      <div
        class="sort-btn"
        [ngClass]="{ 'sort-btn-active': sortDirection === 'desc' }"
        (click)="
          filterChanged.emit({
            sort: { active: filter.key, direction: 'desc' }
          })
        "
      >
        {{ filter.columnType === tableColumnTypes.number ? 'High to low' : 'Descending' }}
      </div>
    </div>

    <form class="mt-base" [formGroup]="form" (ngSubmit)="submit()">
      <div class="mb-sm" *ngIf="operators">
        <mg-dropdown-menu
          [fullWidth]="true"
          [items]="aggregatorItems"
          [selectedItem]="aggregatorControl?.value"
          (selectedItemChanged)="aggregatorControl?.setValue($event)"
        >
        </mg-dropdown-menu>
      </div>

      <div formArrayName="items" *ngFor="let itemForm of $any(itemsControl['controls']); let i = index">
        <ng-container [formGroup]="itemForm">
          @switch (filter.columnType) {
            @case (tableColumnTypes.checkbox) {
              <div
                *ngFor="let item of filter.listItems ?? []"
                class="flex items-center mb-sm cursor-pointer"
              >
                <mg-checkbox
                  [item]="item"
                  labelClass="!text-base !text-txt"
                  controlName="value"
                ></mg-checkbox>
              </div>
            }

            @case (tableColumnTypes.radio) {
              <div
                *ngFor="let item of filter.listItems ?? []"
                class="flex items-center mb-sm cursor-pointer"
              >
                <mg-radio
                  [item]="item"
                  labelClass="!text-base !text-txt"
                  [circleSize]="18"
                  controlName="value"
                ></mg-radio>
              </div>
            }

            @case (tableColumnTypes.date) {
              <mg-range-date-picker
                [standalone]="true"
                [value]="selectedDateInfo"
                [maxDate]="todayString"
                (valueChanged)="onDateChange(itemForm.get('value'), itemForm.get('extraValue'), $event)"
              ></mg-range-date-picker>
            }

            @default {
              <div class="flex">
                <mg-dropdown-menu
                  class="mr-sm"
                  [items]="operators"
                  [selectedItem]="itemForm.get('operator')?.value"
                  (selectedItemChanged)="itemForm.get('operator')?.setValue($event)"
                >
                </mg-dropdown-menu>
                <mg-input
                  controlName="value"
                  [type]="$any(filter.columnType)"
                  size="small"
                  placeholder="Value"
                ></mg-input>

                <div
                  (click)="removeRule(i)"
                  class="flex ml-base p-sm pt-base text-txt-secondary cursor-pointer"
                >
                  <svg-icon key="close" class="!text-xs"></svg-icon>
                </div>
              </div>
            }
          }
        </ng-container>
      </div>

      <div
        *ngIf="operators"
        (click)="addNewRule()"
        class="mt-sm flex items-center text-base leading-base text-txt-secondary cursor-pointer"
      >
        <svg-icon key="add" class="!text-sm"></svg-icon>
        <span class="pl-base">Add filter rule</span>
      </div>
    </form>

    <div class="mt-lg flex items-center justify-between">
      <mg-button
        extraBtnClass="!p-0 !text-txt-secondary"
        variant="ghost"
        size="small"
        (click)="
          filterMenuTrigger.closeMenu();
          filterChanged.emit({
            shouldDelete: true
          })
        "
      >
        Clear
      </mg-button>

      <mg-button extraBtnClass="!p-0" variant="ghost" size="small" (click)="submit()">Filter</mg-button>
    </div>
  </div>
</mat-menu>
