import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import {
  CombinedGuard,
  CurrentUserGuard,
  LoggedInGuard,
  ProfileLayoutComponent
} from '@mg-platform/core/core-feature'
import { ApplicationRoutes } from './app-routes-names'
import { OnlyAdminGuard } from './core/guards/only-admin.guard'
import { MainLayoutComponent } from './layout/main-layout/main-layout/main-layout.component'
import { AdminAuthLayoutComponent } from './layout/auth-layout/auth-layout/auth-layout.component'
import { AdminInfoGuard } from './core/guards/admin-info.guard'

export const appRoutes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: ApplicationRoutes.dashboard },

  {
    path: '',
    canActivate: [CombinedGuard],
    data: {
      guards: [CurrentUserGuard, LoggedInGuard, OnlyAdminGuard, AdminInfoGuard]
    },
    component: MainLayoutComponent,
    children: [
      {
        path: ApplicationRoutes.dashboard,
        loadChildren: () =>
          import('./features/dashboard/dashboard.module').then((m) => m.DashboardModule)
      },
      {
        path: ApplicationRoutes.profile,
        component: ProfileLayoutComponent,
        loadChildren: () => import('./features/profile/profile.module').then((m) => m.ProfileModule)
      },
      {
        path: ApplicationRoutes.config,
        loadChildren: () => import('./features/config/config.module').then((m) => m.ConfigModule)
      },
      {
        path: ApplicationRoutes.organizations,
        loadChildren: () =>
          import('./features/organizations/organizations.module').then((m) => m.OrganizationsModule)
      },
      {
        path: ApplicationRoutes.shops,
        loadChildren: () => import('./features/shops/shops.module').then((m) => m.ShopsModule)
      },
      {
        path: ApplicationRoutes.users,
        loadChildren: () => import('./features/users/users.module').then((m) => m.UsersModule)
      },
      {
        path: ApplicationRoutes.notifications,
        loadChildren: () =>
          import('./features/notifications/notifications.module').then((m) => m.NotificationsModule)
      },
      {
        path: ApplicationRoutes.marketPricing,
        loadChildren: () =>
          import('./features/market-pricing/market-pricing.module').then((m) => m.MarketPricingModule)
      },
      {
        path: ApplicationRoutes.samples,
        loadChildren: () => import('./features/samples/samples.module').then((m) => m.SamplesModule)
      }
    ]
  },

  {
    canActivate: [CurrentUserGuard],
    path: '',
    component: AdminAuthLayoutComponent,
    loadChildren: () =>
      import('./features/admin-account/admin-account.module').then((m) => m.AdminAccountModule)
  },

  // TODO: add 404 page
  { path: '**', pathMatch: 'full', redirectTo: ApplicationRoutes.dashboard }
]

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
