import { ErrorHandler, NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { AppComponent } from './app.component'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { SvgIconsModule } from '@ngneat/svg-icon'
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin'
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin'
import { NgxsModule } from '@ngxs/store'
import { commonIcons, socialIcons } from '@mg-platform/icons'
import { CoreUiModule } from '@mg-platform/core/core-ui'
import { ToastrModule } from 'ngx-toastr'
import { environment, GlobalErrorHandler } from '@mg-platform/core/core-util'
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http'
import { AdminUsersState, UserAccountState } from '@mg-platform/users/users-data-access'
import { AppRoutingModule } from './app-routing.module'
import { FiltersState, StatesState } from '@mg-platform/core/core-data-access'
import { MainLayoutModule } from './layout/main-layout/main-layout.module'
import { CoreFeatureModule, AuthInterceptor } from '@mg-platform/core/core-feature'
import { AdminConfigState } from '@mg-platform/config/config-data-access'
import { AdminShopsState } from '@mg-platform/shops/shops-data-access'
import {
  AdminPosTypeState,
  AdminReportsState
} from '@mg-platform/admin-reports/admin-reports-data-access'
import { OrganizationsState } from '@mg-platform/organizations/organizations-data-access'
import { NotificationsState } from '@mg-platform/notifications/notifications-data-access'
import { AdminAuthLayoutModule } from './layout/auth-layout/auth-layout.module'
import { MarketPricingReportsState } from '@mg-platform/reports/reports-data-access'

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgxsModule.forRoot(
      [
        UserAccountState,
        StatesState,
        AdminConfigState,
        AdminShopsState,
        AdminReportsState,
        OrganizationsState,
        AdminUsersState,
        NotificationsState,
        FiltersState,
        MarketPricingReportsState,
        AdminPosTypeState
      ],
      {
        developmentMode: !environment.production
      }
    ),
    NgxsReduxDevtoolsPluginModule.forRoot({ disabled: environment.production }),
    NgxsLoggerPluginModule.forRoot({ disabled: environment.production }),
    ToastrModule.forRoot({
      timeOut: 6000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true
    }),
    SvgIconsModule.forRoot({
      icons: [...commonIcons, ...socialIcons]
    }),
    CoreUiModule,
    MainLayoutModule,
    AdminAuthLayoutModule,
    CoreFeatureModule
  ],
  providers: [
    provideHttpClient(withInterceptorsFromDi()),
    { provide: 'googleTagManagerId', useValue: environment.googleTagManagerToken },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandler }
  ]
})
export class AppModule {}
