import { IAdminCalendarFilters, IAdminCalendarRequest } from '../../interfaces/admin-calendar.interface'
import { IAdminShopReportsRequest } from '../../interfaces/admin-shop-reports.interface'

export class GetAdminCalendarInfo {
  static readonly type = '[AdminReports] Get Admin Calendar Info'
  constructor(public payload: IAdminCalendarRequest) {}
}

export class SetAdminCalendarFilters {
  static readonly type = '[AdminReports] Set Admin Calendar Filters'
  constructor(public payload: IAdminCalendarFilters) {}
}

export class GetCountReport {
  static readonly type = '[AdminReports] Get Count Report'
  constructor(public excludeBatch: boolean) {}
}

export class GetShopWithReports {
  static readonly type = '[AdminReports] Get Shop With Reports'
  constructor(public payload: IAdminShopReportsRequest) {}
}
