import { Component, EventEmitter, Input, Output } from '@angular/core'
import { UntilDestroy } from '@ngneat/until-destroy'
import { ITabsSection } from './tabs.interface'

@UntilDestroy()
@Component({
  selector: 'mg-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent {
  @Input() sections: ITabsSection[] = []
  @Input() selectedSection: string
  @Input() smallScreensSpaceBetween = false
  @Output() selectedSectionChanged = new EventEmitter<string>()
}
