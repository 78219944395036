export enum PosType {
  NotSet = 'NotSet',
  Protractor = 'Protractor',
  Tcs = 'Tcs',
  SimplyGenius = 'SimplyGenius',
  Autovitals = 'Autovitals',
  ProtractorBatch = 'ProtractorBatch',
  Tekmetric = 'Tekmetric'
}

export const PosTypeToLabel: { [key: string]: string } = {
  NotSet: 'Not Set',
  Protractor: 'Protractor',
  Tcs: 'Tcs',
  SimplyGenius: 'Simply Genius',
  Autovitals: 'Autovitals',
  ProtractorBatch: 'Protractor Batch',
  Tekmetric: 'Tekmetric'
}
