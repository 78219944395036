export const DATE_CONSTANTS = {
  MIN_VALUE: '0001-01-01T00:00:00'
}

export const MONTHS_LIST = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]
