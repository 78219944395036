import { IAdminConfig } from '../../interfaces/admin-config.interface'

export class GetAdminConfig {
  static readonly type = '[AdminConfig] Get Admin Config'
}

export class UpdateAdminConfig {
  static readonly type = '[AdminConfig] Update Admin Config'
  constructor(public payload: IAdminConfig) {}
}
