import { Injectable } from '@angular/core'
import { HttpService } from '@mg-platform/core/core-data-access'
import { Observable } from 'rxjs'
import { CONFIG_API_URL } from './config-api-routes'
import { IAdminConfig } from './interfaces/admin-config.interface'

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  constructor(private http: HttpService) {}

  getAdminConfig(): Observable<IAdminConfig> {
    return this.http.get<IAdminConfig>(CONFIG_API_URL.adminConfig)
  }

  updateAdminConfig(payload: IAdminConfig) {
    return this.http.put(CONFIG_API_URL.adminConfig, payload)
  }
}
