import { environment } from '@mg-platform/core/core-util'

export const ADMIN_REPORTS_API_URL = {
  // Calendar
  calendar: `${environment.apiUrl}/admin/calendar`,

  // Count
  countReport: `${environment.apiUrl}/admin/countreport`,

  // ShopWithReports
  shopWithReports: `${environment.apiUrl}/admin/shopWithReports`,

  // POS Type
  shopPosType: (shopId: string) => `${environment.apiUrl}/admin/shop-pos-type-configuration/${shopId}`,
  runShopImport: `${environment.apiUrl}/admin/run-shop-import`
}
