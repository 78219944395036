import { Injectable } from '@angular/core'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { tap } from 'rxjs/operators'
import { ConfigService } from '../../config.service'
import { GetAdminConfig, UpdateAdminConfig } from './admin-config.actions'
import { AdminConfigStateModel } from './admin-config.model'

@State<AdminConfigStateModel>({
  name: 'adminConfig'
})
@Injectable()
export class AdminConfigState {
  constructor(private configService: ConfigService) {}

  @Selector()
  static config(state: AdminConfigStateModel) {
    return state.config
  }

  @Action(GetAdminConfig)
  getAdminConfig(ctx: StateContext<AdminConfigStateModel>) {
    return this.configService.getAdminConfig().pipe(
      tap((res) => {
        ctx.patchState({ config: res })
      })
    )
  }

  @Action(UpdateAdminConfig)
  updateAdminConfig(ctx: StateContext<AdminConfigStateModel>, { payload }: UpdateAdminConfig) {
    return this.configService.updateAdminConfig(payload)
  }
}
