export const ApplicationRoutes = {
  dashboard: 'dashboard',
  profile: 'profile',
  config: 'config',
  shops: 'shops',
  organizations: 'organizations',
  users: 'users',
  notifications: 'notifications',
  marketPricing: 'market-pricing',
  samples: 'samples'
}
